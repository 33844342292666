import React from "react";

import {
  Route,
  Navigate,
  useLocation,
  useSearchParams,
  Outlet,
  RouteProps,
  createBrowserRouter,
  createRoutesFromElements,
  useNavigate,
  useRouteError,
} from "react-router-dom";

import { Button, Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import LoginPage from "../pages/login.page";
import AdminLayout from "./admin.layout";

import AuthProvider from "../hooks/auth.hook";
import OrderListPage from "../pages/orders/list.page";
import OrderCreatePage from "../pages/orders/create.page";
import ServiceListPage from "../pages/services/list.page";
import ProfessionalCreatePage from "../pages/professionals/create.page";
import ProfessionalListPage from "../pages/professionals/list.page";
import LoginWelcomePage from "../pages/login-welcome.page";
import UnitListPage from "../pages/units/list.page";
import UnitCreatePage from "../pages/units/create.page";
import UnitUpdatePage from "../pages/units/update.page";
import PatientListPage from "../pages/patients/list.page";
import PatientCreatePage from "../pages/patients/create.page";
import PatientUpdatePage from "../pages/patients/update.page";
import DeviceListPage from "../pages/devices/list.page";
import DeviceCreatePage from "../pages/devices/create.page";
import ServiceCreatePage from "../pages/services/create.page";
import ProfessionalProvider from "../hooks/professional.hook";
import CompaniesListPage from "../pages/companies/list.page";
import CompanyCreatePage from "../pages/companies/create.page";
import ServiceUpdatePage from "../pages/services/update.page";
import ExamListPage from "../pages/exams/list.page";
import IndexPage from "../pages/index/index.page";
import InvoiceManagerPage from "../pages/invoices/manager.page";
import { patientById } from "../loaders/patient";

import logo from "../assets/images/hub-logo-light.svg";
import FinancialPage from "../pages/financial/report.page";
import EfiIntegrationPage from "../pages/integrations/efi.page";
import IntegrationListPage from "../pages/integrations/list.page";
import SettingPage from "../pages/settings";
import ConciliationPage from "../pages/financial/conciliation.page";
import { DisplayTvPage } from "../pages/tv/display.page";
import { ConnectTvPage } from "../pages/tv/connection-tv.page";
import { AuthTvProvider } from "../hooks/auth-tv.hook";
import TvLayout from "./tv.layout";

type WithDefaultValue = Record<string, any> | null;
interface WithDefaultSearchProps {
  defaults:
    | WithDefaultValue
    | ((searchParams: URLSearchParams) => WithDefaultValue);
  element: RouteProps["element"];
}

export const WithDefaultSearch: React.FC<WithDefaultSearchProps> = ({
  defaults,
  element,
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  if (defaults) {
    let defaultParams = defaults;

    if (typeof defaults === "function") {
      defaultParams = defaults(searchParams);
    }

    let redirect = false;

    if (defaultParams !== null) {
      Object.entries(defaultParams).forEach(([key, value]) => {
        if (!searchParams.has(key)) {
          searchParams.set(key, value);
          redirect = true;
        }
      });
    }

    if (redirect) {
      return (
        <Navigate
          to={{
            pathname: location.pathname,
            search: searchParams.toString(),
          }}
          replace
        />
      );
    }
  }
  return <>{element}</>;
};

const AuthContext: React.FC<React.PropsWithChildren<unknown>> = () => (
  <AuthProvider>
    <ProfessionalProvider>
      <Outlet />
    </ProfessionalProvider>
  </AuthProvider>
);

const TvContext: React.FC<React.PropsWithChildren<unknown>> = () => (
  <AuthTvProvider>
    <TvLayout>
      <Outlet />
    </TvLayout>
  </AuthTvProvider>
);

const ErrorPage = () => {
  const error: any = useRouteError();
  const navigate = useNavigate();

  if (error?.status === 404) {
    return (
      <Stack
        width="100vw"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="grey.50"
        spacing={4}
      >
        <img src={logo} height={50} alt="Snapay" />
        <Typography sx={{ fontSize: 13, textTransform: "uppercase" }}>
          Pagina não encontrada
        </Typography>
        <Button onClick={() => navigate(-1)}>Voltar a pagina anterior</Button>
      </Stack>
    );
  }

  return (
    <Stack
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="grey.50"
      spacing={4}
    >
      <img src={logo} height={50} alt="Snapay" />
      <Typography sx={{ fontSize: 13, textTransform: "uppercase" }}>
        Ocorreu um erro inesperado
      </Typography>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <Typography sx={{ fontSize: 10 }}>
          Por favor, contactar o suporte informando o código
        </Typography>
        <Typography variant="overline">{btoa(error)}</Typography>
      </Paper>
      {process.env.NODE_ENV !== "production" && (
        <Paper variant="outlined" sx={{ p: 1 }}>
          <Typography variant="overline">{error.toString()}</Typography>
        </Paper>
      )}
    </Stack>
  );
};

const MainRoute = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="tv" element={<TvContext />}>
        <Route index element={<DisplayTvPage />} />
        <Route path="connect" element={<ConnectTvPage />} />
      </Route>
      <Route element={<AuthContext />} errorElement={<ErrorPage />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="login/welcome" element={<LoginWelcomePage />} />
        <Route path="" element={<AdminLayout />}>
          <Route index element={<IndexPage />} />
          <Route path="orders">
            <Route
              index
              element={
                <WithDefaultSearch
                  defaults={(searchParams) => {
                    if (!searchParams.has("id")) {
                      return {
                        status: "waiting_for_service",
                      };
                    }
                    return null;
                  }}
                  element={<OrderListPage />}
                />
              }
            />
            <Route
              path="new"
              loader={({ request }) => {
                const url = new URL(request.url);
                const id = url.searchParams.get("patient");
                if (id) {
                  return patientById(id);
                }
                return null;
              }}
              element={<OrderCreatePage />}
            />
          </Route>
          <Route path="invoices">
            <Route path=":id" element={<InvoiceManagerPage />} />
            {/* <Route path="new" element={<OrderCreatePage />} /> */}
          </Route>
          <Route path="financial">
            {/* <Route path=":id" element={<InvoiceManagerPage />} /> */}
            <Route
              path="report"
              element={
                <WithDefaultSearch
                  defaults={() => {
                    return {
                      startPeriod: moment().startOf("day").format("YYYY-MM-DD"),
                      endPeriod: moment().endOf("day").format("YYYY-MM-DD"),
                    };
                  }}
                  element={<FinancialPage />}
                />
              }
            />
            <Route
              path="conciliation"
              element={
                <WithDefaultSearch
                  defaults={() => {
                    return {
                      type: "healthInsurance",
                      startPeriod: moment().startOf("day").format("YYYY-MM-DD"),
                      endPeriod: moment().endOf("day").format("YYYY-MM-DD"),
                    };
                  }}
                  element={<ConciliationPage />}
                />
              }
            />
          </Route>
          <Route path="exams">
            <Route index element={<ExamListPage />} />
          </Route>
          <Route path="patients">
            <Route index element={<PatientListPage />} />
            <Route path="new" element={<PatientCreatePage />} />
            <Route path=":id" element={<PatientUpdatePage />} />
          </Route>
          <Route path="services">
            <Route index element={<ServiceListPage />} />
            <Route path="new" element={<ServiceCreatePage />} />
            <Route path=":id" element={<ServiceUpdatePage />} />
          </Route>
          <Route path="professionals">
            <Route index element={<ProfessionalListPage />} />
            <Route path="new" element={<ProfessionalCreatePage />} />
          </Route>
          <Route path="units">
            <Route index element={<UnitListPage />} />
            <Route path="new" element={<UnitCreatePage />} />
            <Route path=":id" element={<UnitUpdatePage />} />
          </Route>
          <Route path="devices">
            <Route index element={<DeviceListPage />} />
            <Route path="new" element={<DeviceCreatePage />} />
          </Route>
          <Route path="companies">
            <Route index element={<CompaniesListPage />} />
            <Route path="new" element={<CompanyCreatePage />} />
          </Route>
          <Route path="integrations">
            <Route index element={<IntegrationListPage />} />
            <Route path="efi" element={<EfiIntegrationPage />} />
            <Route path="whatsApp" element={<EfiIntegrationPage />} />
          </Route>
          <Route path="settings">
            <Route index element={<SettingPage />} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

export default MainRoute;
