import {
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Fade,
  Grid,
  Pagination as MuiPagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ConnectedTv } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { DefaultHeader } from "../../components/default-header";
import SearchField from "../../components/fields/search-field";
import InfoLabel from "../../components/info-label";
import PageContent from "../../components/page-content";
import { getDeviceStatus } from "../../helpers/device";
import { Device } from "../../models/device.model";
import { Pagination } from "../../models/pagination.model";
import { Service } from "../../models/service.model";
import http from "../../services/http";
import { useItemModal, useModal } from "../../hooks/modal.hook";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import TextField from "../../components/fields/text-field";
import { useSocket } from "../../hooks/socket.hook";

interface IConnectTvModal {
  device: Device;
}

const ConnectTvModal = ({ device }: IConnectTvModal) => {
  const snackbar = useSnackbar();
  const modalItem = useItemModal();

  const onSubmit = useCallback(
    async (values: any) => {
      const { status } = await http.put<Pagination<Device>>(
        `devices/${device.id}/link`,
        {
          code: values.code,
        }
      );

      if (status === 200) {
        modalItem.close();
        snackbar.enqueueSnackbar("Equipamento emparelhado com sucesso", {
          variant: "success",
        });
      } else {
        snackbar.enqueueSnackbar("Não foi possível emparelhar o equipamento", {
          variant: "error",
        });
      }
    },
    [device.id, modalItem, snackbar]
  );

  return (
    <FormWrapper initialValues={{ code: null }} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Typography>
          Digite o código que aparece na TV e confirme para conectar
        </Typography>
        <TextField
          label="Código de Validação"
          name="code"
          inputProps={{
            sx: { textTransform: "uppercase" },
          }}
        />
        <SubmitButton variant="contained">Conectar</SubmitButton>
      </Stack>
    </FormWrapper>
  );
};

const DeviceListPage: React.FC = () => {
  const socket = useSocket();
  const modal = useModal();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loadingdevices, setLoadingDevices] = useState(false);
  const [devices, setDevices] = useState<Device[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<Pagination<Service>["meta"]>();

  const fetchDevices = useCallback(
    async (searchQueryParams: URLSearchParams) => {
      setLoadingDevices(true);
      const { data, status } = await http.get<Pagination<Device>>("devices", {
        params: {
          paginate: true,
          ...Object.fromEntries(searchQueryParams),
        },
      });
      setLoadingDevices(false);

      if (status === 200) {
        setDevices(data.items);
        setPaginationMeta(data.meta);
      }
    },
    []
  );

  // const toggleVisibility = useCallback(async (device: Device) => {
  //   const { status, data } = await http.put(`devices/${device.id}`, {
  //     status: device.status === "blocked" ? "active" : "blocked",
  //   });

  //   if (status === 200) {
  //     setDevices((old) => {
  //       return [...old].map((s) => ({
  //         ...s,
  //         status: s.id === device.id ? data.status : s.status,
  //       }));
  //     });
  //   }
  // }, []);

  useEffect(() => {
    fetchDevices(searchParams);
  }, [fetchDevices, searchParams]);

  useEffect(() => {
    if (socket.connected) {
      socket?.socket?.on("device::status", () => fetchDevices(searchParams));
    }

    return () => {
      socket?.socket?.off("device::status", () => fetchDevices(searchParams));
    };
  }, [fetchDevices, searchParams, socket.connected, socket?.socket]);

  return (
    <PageContent header={<DefaultHeader title="Equipamentos" />}>
      <Paper>
        <SearchField
          sx={{ m: 2 }}
          queryParamName="search"
          fullWidth
          placeholder="Buscar por descrição, tipo, unidade ou ambiente"
        />
        <Divider />
        <Table>
          <TableBody>
            {devices.map((device) => (
              <TableRow key={device.id}>
                {/* <TableCell sx={{ width: "1%" }}>
                  <Tooltip title="Visibilidade">
                    <Switch
                      disabled={device.status === "validating"}
                      checked={device.status === "active"}
                      onChange={() => toggleVisibility(device)}
                    />
                  </Tooltip>
                </TableCell> */}
                <TableCell>
                  <InfoLabel label="Descrição" value={device.description} />
                </TableCell>
                <TableCell>
                  <InfoLabel label="Unidade" value={device.unit.name} />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    label="Ambiente"
                    value={device.environment?.description}
                  />
                </TableCell>
                <TableCell sx={{ width: "1%" }}>
                  <Fade
                    in={
                      device.status === "validating" && device.type === "totem"
                    }
                    unmountOnExit
                  >
                    <div>
                      <InfoLabel
                        label="Código de Validação"
                        value={device.code}
                      />
                    </div>
                  </Fade>
                  <Fade
                    in={
                      ["created", "disconnected"].includes(device.status) &&
                      device.type === "tv"
                    }
                    unmountOnExit
                  >
                    <Button
                      color="primary"
                      startIcon={<ConnectedTv />}
                      onClick={() => {
                        modal.open({
                          title: `Emparelhar TV`,
                          content: <ConnectTvModal device={device} />,
                          onClose() {
                            setTimeout(() => {
                              fetchDevices(searchParams);
                            }, 5 * 1000);
                          },
                        });
                      }}
                    >
                      Emparelhar
                    </Button>
                  </Fade>
                </TableCell>
                <TableCell sx={{ textAlign: "center", width: "0%" }}>
                  <Chip
                    color={getDeviceStatus(device.status).color}
                    label={getDeviceStatus(
                      device.status
                    ).label.toLocaleUpperCase()}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Collapse in={!loadingdevices && devices.length === 0}>
          <Typography
            variant="body2"
            sx={{ p: 2, display: "block", textAlign: "center" }}
          >
            Nenhum equipamento encontrado
          </Typography>
        </Collapse>
        <Collapse in={loadingdevices} sx={{ textAlign: "center" }}>
          <CircularProgress size={14} sx={{ my: 2 }} />
        </Collapse>
        <Divider />
        <Grid container sx={{ p: 2 }} justifyContent="space-between">
          <Grid item>
            {(paginationMeta?.totalPages ?? 0) > 1 && (
              <MuiPagination
                color="primary"
                page={paginationMeta?.currentPage}
                count={paginationMeta?.totalPages}
                onChange={(_, page) => {
                  searchParams.set("page", String(page));
                  navigate({
                    search: searchParams.toString(),
                  });
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Button component={Link} variant="contained" to="/devices/new">
              Registrar Equipamento
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </PageContent>
  );
};

export default DeviceListPage;
