/* eslint-disable no-console */
export async function Logger(endpoint: string) {
  async function sendLog(data: Record<string, any>): Promise<void> {
    try {
      await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          context: "web",
          message: data,
        }),
      });
    } catch (err) {
      console.error("Failed to send log:", err);
    }
  }

  function logError(
    type: string,
    message: string,
    filename = "",
    lineno = 0,
    colno = 0,
    error: Error = {} as Error
  ): void {
    sendLog({
      type,
      message,
      filename,
      lineno,
      colno,
      stack: error.stack || "",
      timestamp: new Date().toISOString(),
    });
  }

  function log(level: string, ...args: any[]): void {
    const message = args
      .map((arg) => (typeof arg === "object" ? JSON.stringify(arg) : arg))
      .join(" ");
    sendLog({ level, message, timestamp: new Date().toISOString() });
  }

  function init(): void {
    // Captura de erros não tratados
    window.addEventListener("error", (event: ErrorEvent) => {
      logError(
        "error",
        event.message,
        event.filename,
        event.lineno,
        event.colno,
        event.error
      );
    });

    // Captura de erros de Promise não tratados
    window.addEventListener(
      "unhandledrejection",
      (event: PromiseRejectionEvent) => {
        logError(endpoint, "unhandledrejection", event.reason.toString());
      }
    );

    // Sobrescreve console.warn para capturar avisos
    const originalWarn = console.warn;
    console.warn = (...args: any[]) => {
      log("warn", ...args);
      originalWarn.apply(console, args);
    };

    const originalError = console.error;
    console.error = (...args: any[]) => {
      log("error", ...args);
      originalError.apply(console, args);
    };
  }

  init();
}
